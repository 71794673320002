import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";

let sumaPrecios = 0;
class ContratosListItemTranslated extends React.Component {

  render() {
    const { t } = this.props;
    return (
      <div className="list-component">
        {process.env.REACT_APP_APP_NAME != 'Mowitel' ?
          <div className="tarifa-nombre">
            <span className="num-contrato">{t('Contrato.numero',)}</span>
            <span>{this.props.contrato.id}</span>
          </div>
          : ''}
        <div className="tarifa-nombre">
          <span className="tarifa">{t('Contratos.titulota',)} </span>
          <span className="tarifa-nombre-des">{process.env.REACT_APP_APP_NAME == 'yutmovil' ? this.props.contrato.titulo : this.props.contrato.tarifa}</span>
        </div>
        <div className="tarifa-telefono">
          <div className="icon-right" >
            <FontAwesomeIcon icon="chevron-right" className="icon" />
          </div>
        </div>
        <div className={this.props.contrato.telefonos_contrato ? "list-component-asociado" : 'hidden'}>
          <div className="tarifa-nombre">
            <FontAwesomeIcon icon="reply" className="icon" rotation={180} />
            <span className="tarifa">{t('Contratos.tarifa',)} </span>
            <span className="tarifa-nombre-des">{this.props.asociados != '' ? this.props.asociados : this.props.contrato.titulo}</span>
          </div>
          <div className={this.props.contrato.telefonos_contrato ? "tarifa-telefono" : 'hidden'}>
            <FontAwesomeIcon icon="mobile-screen-button" className="icon" />
            <span className="tarifa-nombre">{this.props.contrato.telefonos_contrato}</span>
          </div>
        </div>
      </div>
    );

  }



}
const ContratosListItem = withTranslation('common')(ContratosListItemTranslated)



function useSearchContratos(contratos) {

  const [query, setQuery] = React.useState("");
  const [filteredContratos, setFilteredContratos] = React.useState(contratos);

  React.useMemo(() => {
    const result = contratos.filter(contrato => {
      return `${contrato.tarifa} ${contrato.telefonos_contrato}`.toLowerCase().includes(query.toLowerCase());
    });

    setFilteredContratos(result);
  }, [contratos, query]);

  return { query, setQuery, filteredContratos };
}



function ContratosListTranslated(props) {
  const [t, i18n] = useTranslation('common');
  const contratos = props.contratos;
  const { query, setQuery, filteredContratos } = useSearchContratos(contratos);

  if (filteredContratos.length === 0) {

    return (
      <div>
        <div className="div-buscar">
          <FontAwesomeIcon icon="magnifying-glass" className="icon" />
          <input
            type="text"
            className="form-control"
            value={query}
            onChange={e => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <h5>{t('Contratos.info',)}</h5>
      </div>
    );
  }

  return (
    <div className="facturasList">
      <div className="div-buscar">
        <FontAwesomeIcon icon="magnifying-glass" className="icon" />
        <input
          type="text"
          className="input-buscar"
          placeholder={t('Contratos.buscar',)}
          value={query}
          onChange={e => {
            setQuery(e.target.value);
          }}
        />
      </div>
      <ul className="list-unstyled">
        {sumaPrecios = 0}
        {filteredContratos.map(contrato => {
          sumaPrecios = 0
          props.asociados.map(asociado => {
            if (asociado.contrato_asociado == contrato.id) {
              sumaPrecios = Number(sumaPrecios) + Number(asociado.importe_remesar);
            }
          })
          return (
            <li key={contrato.id}>
              <Link
                className="text-reset text-decoration-none"
                to={{
                  pathname: `/Contratos/${contrato.id}`,
                  state: {
                    fecha: `${contrato.fechainicio}`,
                    id: `${contrato.id}`,
                    titulo: `${contrato.titulo + ' ,   ' + props.asociados.map(asociado => {
                      if (asociado.contrato_asociado == contrato.id) {
                        return asociado.titulo;
                      }
                    })}`,
                    precioOri: `${contrato.importe_remesar}`,
                    telefono: `${contrato.telefonos_contrato}`,
                    precio: `${sumaPrecios}`,
                  }
                }}
              >

                <ContratosListItem contrato={contrato} asociados={props.asociados.map(asociado => {
                  if (asociado.contrato_asociado == contrato.id) {
                    return asociado.titulo;
                  }
                })} />
              </Link>
            </li>
          );
        })

        }
      </ul>
    </div>
  );

}
const ContratosList = withTranslation('common')(ContratosListTranslated)
export default ContratosList;