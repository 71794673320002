
import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import RoamingAire from '../Roaming/RoamingAire';
import RoamingGenerico from '../Roaming/RoamingGenerico';
import Componenteinfo from '../PantallaCarga/ComponenteInfo';



let userConsumo = '';
let estRo = '';

class RoamingTranslated extends React.Component {
    state = {
        loading: true,
        error: false,
        datos: [],
        sinDatos: false,
        info: false,
        estadoRoaming: false,
        mostrarRoaming: false,
        roamingAire: false,
        ddi: '',
        estadoAireDatos: 0,
        estadoAirellama: 0,
        apiNo: false

    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({ loading: true, error: null });

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.telefonos(cliente.usuario).then(
                ({ data }) => (userConsumo = data)
            ).catch(function (err) {
                console.log(err);
            });

            if (!Array.isArray(userConsumo)) {
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false, datos: userConsumo });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };


    consultaRoaming = async (ddi) => {
        this.setState({ loading: true, error: null, roamingAire: false, mostrarRoaming: false });
        estRo = '';
        if (ddi != '') {
            try {
                await api.Clientes.consultarRoaming(ddi).then(
                    ({ data }) => (estRo = data),
                );
                if (estRo == '1' || estRo == '2') {
                    console.log('aki')
                    if (estRo == 1) {
                        this.setState({ estadoRoaming: true });
                    } else {
                        this.setState({ estadoRoaming: false });
                    }
                    this.setState({ mostrarRoaming: true });
                } else if (typeof (estRo['datos']) && typeof (estRo['llama']) && estRo['datos'] != undefined) {
                    this.setState({ roamingAire: true, estadoAireDatos: estRo['datos'], estadoAirellama: estRo['llama'] });
                } else if (estRo == 'No se ha podido recuperar el estado') {
                    this.setState({ info: true });
                } else {
                    this.setState({ apiNo: true });
                }
                this.setState({ loading: false });
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }


    handleChange = async (e) => {
        this.setState({ ddi: e.target.value });
        this.consultaRoaming(e.target.value);
    };


    render() {
        const { t } = this.props;

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }

        if (this.state.info) {
            return <Componenteinfo datos={'Servicios'} />;
        }

        return <div className='roaming-herramientas'>
            <select name="telefono" onChange={this.handleChange} className="select-tlf" value={this.state.ddi}>
                <option key="0" value="" >{t('Consumo.numero',)}</option>
                {this.state.datos.map((dato) =>
                    <option key={dato.id} value={dato}>{dato}</option>
                )}
            </select>
            <hr />
            {this.state.mostrarRoaming ?
                <div className='activar-roaming'>
                    <RoamingGenerico ddi={this.state.ddi} estado={this.state.estadoRoaming} />
                </div>
                : ''}
            {this.state.roamingAire &&
                <>
                    <div className='activar-roaming'>
                        <RoamingAire llamadas={this.state.estadoAirellama} datos={this.state.estadoAireDatos} ddi={this.state.ddi} />
                    </div>
                </>}

            {this.state.apiNo &&
                <>
                    <div className='activar-roaming-no'>
                        <span>Funcionalidad no disponible para este operador</span>
                    </div>
                </>

            }
        </div>
    }

}

const Roaming = withTranslation('common')(RoamingTranslated)
export default Roaming;